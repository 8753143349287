import React from 'react'

const EmployeeHome = () => {
  return (
    <div className='mt-[87px] w-11/12 mx-auto'>
         This is Employee Home page 
    </div>
  )
}

export default EmployeeHome
